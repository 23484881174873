import { Math } from "core-js";
import { shopImageURL } from "../includes/shopUtils";

class ProductDetails extends HTMLElement {
	constructor(product, sdk, cartCallback) {
		super();
		this.product = product;
		this.sdk = sdk;
		this.cartCallback = cartCallback;

		this.quantity = 1;
		this._root = this.attachShadow({ mode: "open" });
		const variantID = product.variants[0].id;
		this.attributes["variantID"] = variantID;
		this.setVariant(variantID);
	}

	set maxQuantity(value) {
		if (this.quantity > value) {
			this.quantity = value;
		}
		this._maxQuantity = value;
	}

	get maxQuantity() {
		return this._maxQuantity;
	}

	setVariant(variantID) {
		this.variant = this.product.variants.find(
			(variant) => variant.id == variantID,
		);
		if (this.product.maxPurchaseUnits) {
			this.maxQuantity = Math.min(
				this.product.maxPurchaseUnits,
				this.variant.remainingUnits,
			);
		} else {
			this.maxQuantity = this.variant.remainingUnits;
		}
		if (this.quantity < 1) {
			this.quantity = 1;
		}
		this.render();
	}

	setButtonLabel() {
		let button = this._root.querySelector("#addToCart");
		button.innerHTML =
			`$${this.quantity * this.variant.sellPrice} add to cart` +
			this.arrowSVG();
	}

	render() {
		const imageSrc = shopImageURL(this.product.imageId, true);
		const otherImages = this.product.otherImageIds.map((i) =>
			shopImageURL(i, true),
		);
		let variantSelector = "";
		if (this.product.variants.length > 1) {
			variantSelector = `
            <div part="field">
                <label part="label" for='varaiant'>Extras</label>
                <select part="select" name='variant' id='variant'>
                    ${this.product.variants.map((variant) => `<option value='${variant.id}' ${variant.id == this.variant.id ? "selected" : ""}>${variant.name}</option>`).join("")}
                </select>
                ${this.selectSVG()}
            </div>
            `;
		}
		this._root.innerHTML = `
            <style>
                * {
                    font-size: var(--fs-prose-body);
                    font-family: var(--body-font);
                }
                ul {
                    padding-left: 1em;
                }
            </style>
            <h2 part="title">${this.product.name}</h2>
            <div part="container">
                <div part="left">
                    <div part="price">$${this.variant.sellPrice}</div>
                    <p part="intro">${this.product.shortDescription}</p>
                    <div part="description">
                        ${this.product.description}
                    </div>
                    <div part="controls">
                        <div part="field">
                            <label part="label" for='quantity'>Quantity</label>
                            <input part="quantity" type='number' id='quantity' name='quantity' value=${this.quantity} max=${this.maxQuantity} min=1 />
                        </div>
                        ${variantSelector}
                    </div>
                    <button part='buy-button' class='btn' id="addToCart"></button>
                </div>
                <div part="right">
                    <img part="img" src="${imageSrc}" />
                    ${otherImages.map((src) => `<img part="img" src="${src}"/>`)}
                </div>
            </div >
            `;
		if (variantSelector) {
			this._root.querySelector("#variant").addEventListener("change", (e) => {
				this.setVariant(e.target.value);
			});
		}

		this.setButtonLabel();

		if (this.maxQuantity > 0) {
			this._root.querySelector("#quantity").addEventListener("change", (e) => {
				this.quantity = e.target.value;
				if (this.quantity > this.maxQuantity) {
					this.quantity = this.maxQuantity;
				} else if (isNaN(this.quantity) || this.quantity < 1) {
					this.quantity = 1;
				}
				e.target.value = this.quantity;
				this.setButtonLabel();
			});

			this._root.querySelector("#addToCart").addEventListener("click", (e) => {
				e.preventDefault();
				this.addToCart();
			});
		} else {
			this._root.querySelector("#addToCart").remove();
			this._root
				.querySelector('[part="controls"]')
				.insertAdjacentHTML(
					"afterend",
					`<p>Currently out of stock, please <a href="${window.STORE_CONFIG.contactURL}">contact us</a> and we'll notify you when we are back in stock.</p>`,
				);
		}
	}

	arrowSVG() {
		// svgs/arrow-right.svg
		return `
            <svg width="35" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M1.167 10.084h31.066m-7.842-8.461l8.46 8.461-8.46 8.46" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round"/></svg>
        `;
	}

	selectSVG() {
		// svgs/stemless-arrow-down.svg
		return `
        <svg part="select-arrow"  width="20px" height="11px" viewBox="0 0 20 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-1146.000000, -3224.000000)" stroke="var(--t-accent-color)" stroke-width="2">
                    <g transform="translate(248.666626, 3160.500000)">
                <g
                    transform="translate(907.333374, 67.500000) rotate(-180.000000) translate(-907.333374, -67.500000) translate(882.333374, 42.500000)">
                    <polyline
                        transform="translate(25.000000, 24.000000) rotate(-90.000000) translate(-25.000000, -24.000000) "
                        points="20.7698333 15.5396667 29.2301667 24.0006296 20.7698333 32.4603333"></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        `;
	}

	addToCart() {
		this.style.opacity = 0.2;
		document.body.classList.add("thinking");
		this.style.pointerEvents = "none";
		this.sdk
			.addProductToCart(this.quantity, this.variant.id)
			.then((cartResponse) => {
				this.style = {};
				document.body.classList.remove("thinking");
				this.cartCallback(cartResponse);
			})
			.catch((e) => {
				// TODO bugsnag
				this.style = {};
				document.body.classList.remove("thinking");
				throw e;
			});
	}
}

function initProductDetails() {
	window.customElements.define("product-details", ProductDetails);
}

export { initProductDetails, ProductDetails };
