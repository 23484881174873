import { shopImageURL } from "../includes/shopUtils";

export class CartItem extends HTMLElement {
	constructor(cartItem, updateCartCallback, updateTotalCallback, sdk) {
		super();
		// some reason this is null sometimes??
		if (!cartItem) {
			return;
		}
		this.cartItem = cartItem;
		this.updateCartCallback = updateCartCallback;
		this.updateTotalCallback = updateTotalCallback;
		this.sdk = sdk;
		this._root = this.attachShadow({ mode: "open" });
		this.render();
	}

	render() {
		let product = this.cartItem.product;
		const imageSrc = shopImageURL(product.imageId, false);
		this._root.innerHTML = `
            <style>
                * {
                    font-family: var(--body-font);
                    font-size: var(--fs-prose-body);
                    box-sizing: border-box;
                }
                .container {
                    display: grid;
                    grid-template-columns: 100px auto;
                    column-gap: 20px;
                }

                .title {
                    font-weight: 600;
                }

                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 7%;
                }

                .controls {
                    display: flex;
                    margin-top: 10px;
                    align-items: center;
                }

                button {
                    margin-left: 5px;
                    border: 0;
                    appearance: none;
                    background: none;
                    font-size: var(--fs-prose-body);
                    color: var(--t-text-color-light);
                }

                button:hover {
                    cursor: pointer;
                }

                input {
                    width: 50px;
                    height: 25px;
                    font-size: var(--fs-prose-body);
                    border: var(--t-table-border);
                    border-radius: 6px;
                    font-size: 18px;
                    padding-left: 8px;

                }
            </style>
            <div class='container'>
                <img src='${imageSrc}' />
                <div>
                    <div class='title'>${product.variantName}</div>
                    <span class='total'>$${this.cartItem.total}</span>
                    <div class='controls'>
                        <input type='number' min=1 value=${this.cartItem.quantity} max=${product.maxPurchaseUnits} />
                        <button>Remove</button>
                    </div>
                </div>
            </div>
        `;
		this.attachHandlers();
	}

	set total(value) {
		this._total = value;
		this._root.querySelector(".total").innerHTML = `$${value}`;
	}

	get total() {
		return this._total;
	}

	attachHandlers() {
		this._root
			.querySelector("button")
			.addEventListener("click", this.removeFromCart.bind(this));
		this._root
			.querySelector('input[type="number"]')
			.addEventListener("change", this.updateQuantity.bind(this));
	}

	handleCartResponse(cartResponse) {
		this.updateCartCallback(cartResponse);
		this.thonked();
	}

	removeFromCart() {
		this.thinking();
		this.sdk
			.removeProductFromCart(this.cartItem.product.variantId)
			.then((response) => {
				this.handleCartResponse(response);
			});
	}

	thinking() {
		this.style.opacity = 0.2;
		document.body.classList.add("thinking");
	}

	thonked() {
		this.style.opacity = 1;
		document.body.classList.remove("thinking");
	}

	updateQuantity(e) {
		this.thinking();
		let quantity = e.target.value;
		if (isNaN(quantity) || quantity < 1) {
			quantity = 1;
		}
		this.sdk
			.updateProductInCart(quantity, this.cartItem.product.variantId)
			.then((response) => {
				this.cartItem = response.cartItems.find(
					(responseCartItem) =>
						this.cartItem.product.variantId ==
						responseCartItem.product.variantId,
				);
				this.total = this.cartItem.total;
				this.updateTotalCallback(response.total);
				this.thonked();
			});
	}
}
