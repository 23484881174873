import { CartItem } from "./CartItem";

class Cart extends HTMLElement {
	constructor(sdk) {
		super();
		this.sdk = sdk;
		this._cartUpdateCalback = null;
		this._root = this.attachShadow({ mode: "open" });
		this._items = [];
		this._cartResponse = null;
		this.render();

		this.addEventListener("click", (e) => {
			const eventPath = e.composedPath();
			if (eventPath[0] == this) {
				// Clicked the background, not a child element
				this.open = false;
			}
		});
	}

	get cartResponse() {
		return this._cartResponse;
	}

	set cartResponse(response) {
		if (response) {
			if (this._cartResponse && !this.open) {
				this.open = true;
			}
			this.items = response.cartItems;
			this.displayTotal(response.total);
		} else {
			this.items = [];
		}
		this._cartResponse = response;
	}

	get items() {
		return this._items;
	}

	set items(cartItems) {
		this._items = cartItems;
		const items = this._root.querySelector('[part="items"]');
		items.innerHTML = "";
		if (cartItems.length == 0) {
			items.innerHTML = `
            <span part="empty">Your shopping cart is empty</span>
            <a part="store-button" href="${window.STORE_CONFIG.storeURL}">Visit our online store ${this.arrowSVG()}</a>
            `;
		} else {
			cartItems.forEach((cartItem) => {
				let cartNode = new CartItem(
					cartItem,
					this.cartUpdateCallback,
					this.displayTotal.bind(this),
					this.sdk,
				);
				items.appendChild(cartNode);
			});
		}
	}

	set cartUpdateCallback(func) {
		this._cartUpdateCalback = func;
	}

	get cartUpdateCallback() {
		return this._cartUpdateCalback;
	}

	static get observedAttributes() {
		return ["open"];
	}

	get open() {
		return this.hasAttribute("open");
	}

	set open(value) {
		if (value) {
			this.setAttribute("open", "");
		} else {
			this.removeAttribute("open");
		}
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (this.open) {
			this.setAttribute("tabindex", 0);
			this.setActiveStyle();
		} else {
			this.setAttribute("tabindex", 1);
			this.setInactiveStyle();
		}
	}

	render() {
		this._root.innerHTML = `
            <div part='container' id='#cart'>
                <h3 part='heading'>Cart</h3>
                <div part='items'>
                    Loading...
                </div>
                <div part='total'>
                </div>
            </div>
        `;
	}

	displayTotal(amount) {
		let totalDiv = this._root.querySelector('[part="total"]');
		if (amount) {
			totalDiv.innerHTML = `
            <span>Total cost</span><span>$${amount}</span>
            <button part='checkout'>Proceed to checkout ${this.arrowSVG()}</button>
            `;
			const checkoutButton = this._root.querySelector(
				'button[part="checkout"]',
			);
			checkoutButton.addEventListener("click", (e) => {
				e.preventDefault();
				document.body.classList.add("thinking");
				this.sdk.checkout(window.STORE_CONFIG.successURL);
			});
		} else {
			totalDiv.innerHTML = "";
		}
	}

	setActiveStyle() {
		this.style.zIndex = 10;
		this.style.opacity = 1;
		this.style.transitionDelay = "0ms";
	}

	setInactiveStyle() {
		this.style.zIndex = -10;
		this.style.opacity = 0;
		this.style.transitionDelay = "300ms";
	}

	arrowSVG() {
		// svgs/arrow-right.svg
		return `
            <svg width="35" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M1.167 10.084h31.066m-7.842-8.461l8.46 8.461-8.46 8.46" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round"/></svg>
        `;
	}
}

class CartControl extends HTMLElement {
	constructor(cart) {
		super();
		this.addEventListener("click", (e) => {
			e.preventDefault();
			cart.open = !cart.open;
		});

		this.classList.add("l-navigation__actions-link", "l-navigation__cart");
		this._count = 0;
		this.innerHTML = 'Cart <span class="l-navigation__cart-count">0</span>';
	}

	get count() {
		return this._count;
	}

	set count(value) {
		this._count = value;
		this.innerHTML = `Cart <span class="l-navigation__cart-count"> ${value} </span>`;
	}
}

function initCart() {
	window.customElements.define("shop-cart", Cart);
	window.customElements.define("cart-item", CartItem);
	window.customElements.define("cart-control", CartControl);
}

export { Cart, CartControl, initCart };
